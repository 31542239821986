import { useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  GET_ARCHIVED_AUDIT_BY_ID,
  GetArchivedAuditByIdQueryResult,
  GetArchivedAuditByIdQueryVariables,
} from "Graphql/AuditQueries";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import { OptionType } from "Models/OptionType";
import { RouteParams } from "Models/RouteParams";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { AuditDetails } from "Views/Components/AuditOverview/AuditDetails";
import { ArchivedCarList } from "Views/Components/CarList/ArchivedCarList";

interface Props {
  setLastVisitedArchivedAuditId(auditId: string): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    archivedPadding: {
      padding: theme.spacing(1),
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
  }),
);

export const ArchivedAuditOverview: FC<Props> = ({
  setLastVisitedArchivedAuditId,
}) => {
  const { auditId } = useParams<RouteParams>();
  setLastVisitedArchivedAuditId(auditId);
  const classes = useStyles();
  const { t } = useTranslation(["audit", "errors", "archive"]);

  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();

  const {
    loading: auditLoading,
    error: auditError,
    data: auditQueryResult,
    refetch,
  } = useQuery<
    GetArchivedAuditByIdQueryResult,
    GetArchivedAuditByIdQueryVariables
  >(GET_ARCHIVED_AUDIT_BY_ID, {
    variables: {
      id: auditId || "undefined",
    },
  });

  const refetchAudit = () => {
    refetch({
      id: auditId || "undefined",
    });
  };

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    auditQueryResult?.archivedAudit.fpsoArea.areaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
  };

  const Option = CheckboxOption;

  if (auditLoading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (auditError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:auditFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.archivedPadding}>
      {auditQueryResult && auditQueryResult.archivedAudit ? (
        <>
          <Grid item xs={12}>
            <AuditDetails
              audit={auditQueryResult.archivedAudit}
              isArchived={true}
              fpsoAreaName={auditQueryResult.archivedAudit.fpsoArea.areaName}
              fpsoName={auditQueryResult.archivedAudit.fpsoArea.fpso.name}
              refetchAudit={refetchAudit}
            />
          </Grid>
          <Grid item xs={10} className={classes.dropdown}>
            <Select
              onChange={option => handleChange(option)}
              options={subSystemOptions}
              isMulti
              autoFocus
              isSearchable
              isClearable
              styles={CustomStylesSubSystemOptions}
              placeholder={t("fpsos:helperTextCARsFilterSubSystems")}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ArchivedCarList
              audit={auditQueryResult.archivedAudit}
              isArchived={true}
              selectedSubSystems={selectedSubSystems}
              fpsoAreaName={auditQueryResult.archivedAudit.fpsoArea.areaName}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {t("errors:archiveAuditFetchError")}
        </Grid>
      )}
    </Grid>
  );
};
