import { useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import {
  GET_ARCHIVED_AUDITS_BY_FPSO_AREA,
  GetArchivedAuditsByFpsoAreaQueryResult,
  GetArchivedAuditsByFpsoAreaQueryVariables,
} from "Graphql/AuditQueries";
import {
  GET_FPSO_AREA_BY_ID,
  GetFpsoAreaByIdQueryResult,
  GetFpsoAreaByIdQueryVariables,
} from "Graphql/FpsoQueries";
import { CheckboxOption } from "Logic/SubSystemOptions/CheckboxOption";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import { CustomStylesSubSystemOptions } from "Logic/SubSystemOptions/CustomStylesSubSystemOptions";
import { SubSystemOptionsLogic } from "Logic/SubSystemOptions/SubSystemOptionsLogic";
import MaterialTable from "material-table";
import { ArchiveRow } from "Models/ArchiveRow";
import { OptionType } from "Models/OptionType";
import { RouteParams } from "Models/RouteParams";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { ArchivedAuditIcon } from "Views/Components/Icons/ArchivedAuditIcon";
import { TableIcons } from "Views/Components/Icons/TableIcons";

interface Props {
  readonly lastVisitedArchivedAuditId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableTitle: {
      textAlign: "center",
      display: "inline",
      verticalAlign: "30%",
    },
    dropdown: {
      display: "flex",
      marginBottom: theme.spacing(2),
      minWidth: 200,
    },
  }),
);

export const ArchivedAudits: FC<Props> = ({
  lastVisitedArchivedAuditId: lastViewedAuditId,
}) => {
  const { fpsoAreaId } = useParams<RouteParams>();
  const { t } = useTranslation(["archive", "errors", "fpsos"]);
  const history = useHistory();
  const classes = useStyles();

  const [tableData, setTableData] = useState<ArchiveRow[]>([]);
  const [selectedSubSystems, setSelectedSubSystems] = useState<
    MultiValue<OptionType>
  >();

  const handleArchiveReceived = () => {
    const data: ArchiveRow[] = [];
    archiveData?.archivedAudits.forEach((item: any) => {
      data.push({
        ...item,
        leadAuditor: item.leadAuditor.fullName,
      });
    });
    setTableData(data);
  };

  const { data: fpsoAreaInfo } = useQuery<
    GetFpsoAreaByIdQueryResult,
    GetFpsoAreaByIdQueryVariables
  >(GET_FPSO_AREA_BY_ID, { variables: { id: fpsoAreaId || "undefined" } });

  const subSystemOptionsBlank: OptionType[] = [];
  const subSystemOptions = SubSystemOptionsLogic(
    fpsoAreaInfo?.fpsoArea.areaName,
    subSystemOptionsBlank,
  );

  const handleChange = (option: MultiValue<OptionType>) => {
    setSelectedSubSystems(option);
  };

  const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
    selectedSubSystems,
  );

  let archivedAuditsWhereVariables = {};
  if (selectedSubSystemsConversion.selectedSubSystemsString) {
    archivedAuditsWhereVariables = {
      fpsoAreaId: { _eq: fpsoAreaId },
      items_with_sub_systems: {
        subSystemType: {
          _in:
            selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        },
      },
    };
  } else {
    archivedAuditsWhereVariables = { fpsoAreaId: { _eq: fpsoAreaId } };
  }

  const {
    loading: archivedAuditsLoading,
    error: auditError,
    data: archiveData,
    refetch,
  } = useQuery<
    GetArchivedAuditsByFpsoAreaQueryResult,
    GetArchivedAuditsByFpsoAreaQueryVariables
  >(GET_ARCHIVED_AUDITS_BY_FPSO_AREA, {
    fetchPolicy: "network-only",
    variables: {
      fpsoAreaId: fpsoAreaId || "undefined",
      whereVariables: archivedAuditsWhereVariables,
    },
    onCompleted: handleArchiveReceived,
  });

  const refetchArchivedAuditsFunction = useCallback(() => {
    refetch({
      fpsoAreaId: fpsoAreaId || "undefined",
      whereVariables: archivedAuditsWhereVariables,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // Disabled warnings here for now as including selectedSubSystemsSubSystemTypeArray as a
  // dependency causes an infinite loop, and other "solutions" for the
  // missing dependencies error for both useCallback and useEffect
  // result in a similar loop or just unexpected behaviour

  useEffect(() => {
    refetchArchivedAuditsFunction();
  }, [selectedSubSystems]); // eslint-disable-line react-hooks/exhaustive-deps
  // Disabled warnings here for now as including selectedSubSystemsSubSystemTypeArray as a
  // dependency causes an infinite loop, and other "solutions" for the
  // missing dependencies error for both useCallback and useEffect
  // result in a similar loop or just unexpected behaviour

  const handleRowClick = (rowData: ArchiveRow | ArchiveRow[]) => {
    window.scrollTo(0, 0);
    if (Array.isArray(rowData)) {
      history.push(`audit/${rowData[0].id}`);
    } else {
      history.push(`audit/${rowData.id}`);
    }
  };

  const Option = CheckboxOption;

  if (auditError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:archivedAuditsFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.dropdown}>
        <Select
          onChange={option => handleChange(option)}
          options={subSystemOptions}
          isMulti
          autoFocus
          isSearchable
          isClearable
          styles={CustomStylesSubSystemOptions}
          placeholder={t("fpsos:helperTextArchivedAuditsFilterSubSystems")}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{
            Option,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          isLoading={archivedAuditsLoading}
          options={{
            draggable: false,
            padding: "default",
            pageSizeOptions: [10, 20, 30],
            pageSize: 10,
            emptyRowsWhenPaging: false,
            detailPanelColumnAlignment: "right",
            actionsColumnIndex: -1,
            rowStyle: rowData => ({
              backgroundColor:
                lastViewedAuditId === rowData.id ? Colors.ITEM_SELECTED : "",
            }),
            headerStyle: {
              zIndex: 0,
              fontWeight: "bold",
            },
          }}
          columns={[
            {
              title: t("archive:dateEntryCreated"),
              field: "createdAt",
              type: "date",
              render: rowData => (
                <>
                  {rowData.createdAt ? (
                    <>
                      {moment(rowData.createdAt).format(
                        DateConstants.TIME_DATE_FORMAT,
                      )}
                    </>
                  ) : (
                    <>{t("cars:notAvailable")}</>
                  )}
                </>
              ),
            },
            { title: t("archive:auditReference"), field: "auditReference" },
            {
              title: t("archive:auditStartDate"),
              field: "startDate",
              type: "date",
            },
            {
              title: t("archive:auditEndDate"),
              field: "endDate",
              type: "date",
            },
            { title: t("archive:auditScore"), field: "auditScore" },
            { title: t("archive:leadAuditor"), field: "leadAuditor" },
          ]}
          data={tableData}
          actions={[
            {
              icon: () => (
                <OpenInNewIcon style={{ fill: "grey", fontSize: 28 }} />
              ),
              onClick: (event, rowData) => handleRowClick(rowData),
              tooltip: t("archive:openTip"),
            },
          ]}
          icons={TableIcons}
          title={
            <Tooltip title={t<string>("archive:titleTooltip")} arrow={true}>
              <Typography variant="h6">
                <ArchivedAuditIcon />
                <div className={classes.tableTitle}>
                  {archiveData?.fpsoArea.fpso.name +
                    " - " +
                    archiveData?.fpsoArea.areaName +
                    " - " +
                    t("archive:title")}
                </div>
              </Typography>
            </Tooltip>
          }
          onRowClick={(event, rowData) => {
            if (rowData) {
              handleRowClick(rowData);
            }
          }}
          localization={{
            header: {
              actions: "",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
