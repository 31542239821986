import { gql } from "@apollo/client";
import { CAR } from "Models/CAR";

export const GET_CARS_BY_AUDIT = gql`
  query getCarsByAudit($whereVariables: cars_bool_exp) {
    cars(where: $whereVariables, order_by: { createdAt: desc }) {
      id
      carReference
      class
      status
      performanceIndicator
      createdAt
      reporter {
        id
        fullName
      }
      agreedVerificationDate
      actualVerificationDate
      carScore
      componentsAffected
      systemAreasAffected
      affectedSince
      initialAgreedVerificationDate
      initialClass
      isDeleted
      operatorActionRequired
      anpgActionRequired
      auditorActionRequired
      fpsoArea: fpso_area {
        fpso {
          block {
            owner {
              companyName
            }
          }
        }
      }
      englishTranslation: car_translations(
        where: { languageCode: { _eq: en } }
      ) {
        id
        languageCode
        auditFinding
        description
        furtherDescription
        languageCode
      }
      portugueseTranslation: car_translations(
        where: { languageCode: { _eq: pt } }
      ) {
        id
        languageCode
        auditFinding
        description
        furtherDescription
        languageCode
      }
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
  }
`;

export interface GetCarsByAuditQueryResult {
  cars: CAR[];
}

export interface GetCarsByAuditQueryVariables {
  whereVariables: object;
}

export const INSERT_CAR = gql`
  mutation insertCar(
    $id: uuid!
    $class: car_classes_enum
    $carScore: Int
    $initialClass: car_classes_enum
    $descriptionEnglish: String
    $descriptionPortuguese: String
    $reporterId: uuid!
    $auditId: uuid!
    $fpsoAreaId: uuid!
    $createdAt: timestamptz
  ) {
    insertCar: insert_cars(
      objects: {
        id: $id
        class: $class
        carScore: $carScore
        initialClass: $class
        performanceIndicator: Grey
        status: OPEN
        reporterId: $reporterId
        auditId: $auditId
        fpsoAreaId: $fpsoAreaId
        createdAt: $createdAt
      }
    ) {
      returning {
        id
      }
    }
    insertEnglishCarTranslation: insert_car_translations(
      objects: {
        languageCode: en
        description: $descriptionEnglish
        carId: $id
      }
    ) {
      returning {
        id
      }
    }
    insertPortugueseCarTranslation: insert_car_translations(
      objects: {
        languageCode: pt
        description: $descriptionPortuguese
        carId: $id
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CAR = gql`
  mutation updateCar(
    $id: uuid!
    $class: car_classes_enum
    $status: car_status_enum
    $agreedVerificationDate: date
    $actualVerificationDate: date
    $affectedSince: date
    $initialAgreedVerificationDate: date
    $carScore: Int
    $componentsAffected: String
    $systemAreasAffected: system_areas_enum
    $englishTranslationId: uuid
    $acceptedEvidenceEnglish: String
    $auditFindingEnglish: String
    $communicationsOnCarEnglish: String
    $descriptionEnglish: String
    $furtherDescriptionEnglish: String
    $portugueseTranslationId: uuid
    $acceptedEvidencePortuguese: String
    $auditFindingPortuguese: String
    $communicationsOnCarPortuguese: String
    $descriptionPortuguese: String
    $furtherDescriptionPortuguese: String
    $createdAt: timestamptz
  ) {
    update_cars(
      where: { id: { _eq: $id } }
      _set: {
        class: $class
        status: $status
        agreedVerificationDate: $agreedVerificationDate
        actualVerificationDate: $actualVerificationDate
        affectedSince: $affectedSince
        initialAgreedVerificationDate: $initialAgreedVerificationDate
        carScore: $carScore
        componentsAffected: $componentsAffected
        systemAreasAffected: $systemAreasAffected
        createdAt: $createdAt
      }
    ) {
      returning {
        id
      }
    }
    upsert_english_translation: insert_car_translations(
      objects: {
        id: $englishTranslationId
        auditFinding: $auditFindingEnglish
        acceptedEvidence: $acceptedEvidenceEnglish
        communicationsOnCar: $communicationsOnCarEnglish
        description: $descriptionEnglish
        furtherDescription: $furtherDescriptionEnglish
        languageCode: en
        carId: $id
      }
      on_conflict: {
        constraint: car_translations_pkey
        update_columns: [
          auditFinding
          acceptedEvidence
          description
          furtherDescription
          communicationsOnCar
          carId
        ]
      }
    ) {
      returning {
        id
      }
    }
    upsert_portuguese_translation: insert_car_translations(
      objects: {
        id: $portugueseTranslationId
        auditFinding: $auditFindingPortuguese
        acceptedEvidence: $acceptedEvidencePortuguese
        communicationsOnCar: $communicationsOnCarPortuguese
        description: $descriptionPortuguese
        furtherDescription: $furtherDescriptionPortuguese
        languageCode: pt
        carId: $id
      }
      on_conflict: {
        constraint: car_translations_pkey
        update_columns: [
          auditFinding
          acceptedEvidence
          description
          furtherDescription
          communicationsOnCar
          carId
        ]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const SOFT_DELETE_CAR = gql`
  mutation softDeleteCar($id: uuid!) {
    update_cars(where: { id: { _eq: $id } }, _set: { isDeleted: true }) {
      returning {
        id
      }
    }
  }
`;

export const GET_ARCHIVED_CARS_BY_AUDIT_SNAPSHOT = gql`
  query getArchivedCarsByAuditSnapshot(
    $auditSnapshotId: uuid
    $whereVariables: car_snapshots_bool_exp
  ) {
    carSnapshots: car_snapshots(
      where: $whereVariables
      order_by: { createdAt: desc }
    ) {
      id
      carReference
      class
      status
      performanceIndicator
      createdAt
      reporter {
        id
        fullName
      }
      agreedVerificationDate
      actualVerificationDate
      carScore
      componentsAffected
      systemAreasAffected
      affectedSince
      initialAgreedVerificationDate
      initialClass
      isDeleted
      carId
      operatorActionRequired
      anpgActionRequired
      auditorActionRequired
      fpsoArea: fpso_area {
        fpso {
          block {
            owner {
              companyName
            }
          }
        }
      }
      englishTranslation: car_translations_snapshots(
        where: { languageCode: { _eq: en } }
      ) {
        id
        languageCode
        auditFinding
        description
        furtherDescription
        languageCode
        communicationsOnCar
        acceptedEvidence
      }
      portugueseTranslation: car_translations_snapshots(
        where: { languageCode: { _eq: pt } }
      ) {
        id
        languageCode
        auditFinding
        description
        furtherDescription
        languageCode
        communicationsOnCar
        acceptedEvidence
      }
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
  }
`;

export interface GetArchivedCarsByAuditSnapshotQueryResult {
  carSnapshots: CAR[];
}

export interface GetArchivedCarsByAuditSnapshotQueryVariables {
  auditSnapshotId: string;
  whereVariables: object;
}

export const GET_CAR_EVIDENCE_DOCUMENTS_BY_CAR_ID = gql`
  query getCarEvidenceDocumentsByCarId($id: uuid!) {
    car: cars_by_pk(id: $id) {
      id
      carReference
      carEvidenceDocuments: car_evidences(order_by: { updatedAt: desc }) {
        id
        documentDescription
        evidenceDocumentName
        evidenceDocumentReference
        evidenceStatus
        createdAt
        updatedAt
        revision
        documentNumber
        submitter {
          id
          fullName
        }
        comments: car_evidence_discussions(order_by: { createdAt: desc }) {
          id
          content
          createdAt
          updatedAt
          commenter {
            id
            fullName
          }
        }
        itemsWithSubSystems: items_with_sub_systems {
          createdAt
          id
          itemId
          subSystemType
          updatedAt
        }
      }
    }
  }
`;

export interface GetCarEvidenceDocumentsByCarIdQueryResult {
  car: CAR;
}

export interface GetCarEvidenceDocumentsByCarIdQueryVariables {
  id: string;
}

export const INSERT_CAR_EVIDENCE_DOCUMENT = gql`
  mutation insertCarEvidence(
    $evidenceDocumentName: String
    $evidenceDocumentReference: String
    $evidenceStatus: car_evidence_status_enum
    $revision: String
    $documentNumber: String
    $documentDescription: String
    $carId: uuid!
    $submitterId: uuid!
    $createdAt: timestamptz
  ) {
    result: insert_car_evidence(
      objects: {
        evidenceDocumentName: $evidenceDocumentName
        evidenceDocumentReference: $evidenceDocumentReference
        evidenceStatus: $evidenceStatus
        revision: $revision
        documentNumber: $documentNumber
        documentDescription: $documentDescription
        carId: $carId
        submitterId: $submitterId
        createdAt: $createdAt
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_CAR_EVIDENCE_DOCUMENT = gql`
  mutation deleteCarEvidenceDocument($id: uuid!) {
    result: delete_car_evidence(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_CAR_EVIDENCE_DOCUMENT = gql`
  mutation updateCarEvidenceDocument(
    $id: uuid!
    $evidenceDocumentName: String
    $revision: String
    $documentNumber: String
    $documentDescription: String
    $evidenceStatus: car_evidence_status_enum
    $createdAt: timestamptz
  ) {
    update_car_evidence(
      where: { id: { _eq: $id } }
      _set: {
        evidenceDocumentName: $evidenceDocumentName
        revision: $revision
        documentNumber: $documentNumber
        documentDescription: $documentDescription
        evidenceStatus: $evidenceStatus
        createdAt: $createdAt
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_CAR_EVIDENCE_COMMENT = gql`
  mutation insertCarEvidenceComment(
    $id: uuid!
    $carEvidenceId: uuid!
    $commenterId: uuid!
    $content: String
  ) {
    result: insert_car_evidence_discussion(
      objects: {
        id: $id
        carEvidenceId: $carEvidenceId
        commenterId: $commenterId
        content: $content
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_CAR_EVIDENCE_COMMENT = gql`
  mutation updateCarEvidenceComment($id: uuid!, $content: String) {
    update_car_evidence_discussion(
      where: { id: { _eq: $id } }
      _set: { content: $content }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_CAR_EVIDENCE_COMMENT = gql`
  mutation deleteCarEvidenceComment($id: uuid!) {
    result: delete_car_evidence_discussion(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
