import { useMutation, useQuery } from "@apollo/client";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  // Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import Colors from "Constants/Colors";
import { SubSystemType } from "Enums/SubSystemType";
import { SystemArea } from "Enums/SystemArea";
import {
  DELETE_FPSO_AREA_SYSTEM_COMPONENT,
  DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID,
  GET_SYSTEM_COMPONENTS_BY_FPSO_AREA,
  GetSystemComponentsByFpsoAreaQueryResult,
  GetSystemComponentsByFpsoAreaQueryVariables,
  INSERT_FPSO_AREA_SYSTEM_COMPONENT,
  INSERT_SUB_SYSTEM_ITEM,
  UPDATE_FPSO_AREA_SYSTEM_COMPONENT,
} from "Graphql/FpsoQueries";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import MaterialTable from "material-table";
import { FpsoAreaSystemComponent } from "Models/FpsoAreaSystemComponent";
import { InitializedFpsoAreaSystemComponent } from "Models/InitialFpsoAreaSystemComponent";
import { OptionType } from "Models/OptionType";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import { TableIcons } from "../../Icons/TableIcons";
import { FpsoAreaSystemComponentAdd } from "./FpsoAreaSystemComponentAdd";
import { FpsoAreaSystemComponentDetailsPanel } from "./FpsoAreaSystemComponentDetailsPanel";

interface Props {
  readonly fpsoAreaId: string;
  readonly fpsoName: string | undefined;
  readonly fpsoAreaName: string | undefined;
  setFpsoAreaSystemComponentAddFormDisabled(): void;
  fpsoAreaSystemComponentAddFormEnabled: boolean;
  readonly selectedSubSystems: MultiValue<OptionType> | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    fpsoAreaSystemDetails: {
      marginTop: theme.spacing(2),
    },
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
  }),
);

export const FpsoAreaSystemComponentList: FC<Props> = ({
  fpsoAreaId,
  fpsoName,
  fpsoAreaName,
  setFpsoAreaSystemComponentAddFormDisabled,
  fpsoAreaSystemComponentAddFormEnabled,
  selectedSubSystems,
}) => {
  const { t } = useTranslation("fpsos");
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const uuidv4 = require("uuid/v4");

  const [
    insertFpsoAreaSystemComponent,
    // { error: insertFpsoAreaSystemComponentError },
  ] = useMutation(INSERT_FPSO_AREA_SYSTEM_COMPONENT);
  const [
    updateFpsoAreaSystemComponent,
    // { error: updateFpsoAreaSystemComponentError },
  ] = useMutation(UPDATE_FPSO_AREA_SYSTEM_COMPONENT);
  const [deleteSubSystemItem] = useMutation(DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID);
  const [addSubSystemItem] = useMutation(INSERT_SUB_SYSTEM_ITEM);
  const [
    deleteFpsoAreaSystemComponent,
    // mutationResult,
  ] = useMutation(DELETE_FPSO_AREA_SYSTEM_COMPONENT);

  const [tableData, setTableData] = useState<FpsoAreaSystemComponent[]>([]);
  const [selectedComponentRow, setSelectedComponentRow] = useState<
    FpsoAreaSystemComponent
  >();

  const handleSystemComponentsReceived = () => {
    const data: FpsoAreaSystemComponent[] = [];
    systemComponentsData?.fpsoAreaSystemComponents.forEach(item => {
      data.push({
        ...item,
      });
    });
    setTableData(data);
  };

  const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
    selectedSubSystems,
  );

  let systemComponentsWhereVariables = {};
  if (selectedSubSystemsConversion.selectedSubSystemsString) {
    systemComponentsWhereVariables = {
      fpsoAreaId: { _eq: fpsoAreaId },
      items_with_sub_systems: {
        subSystemType: {
          _in:
            selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        },
      },
    };
  } else {
    systemComponentsWhereVariables = { fpsoAreaId: { _eq: fpsoAreaId } };
  }

  const { data: systemComponentsData, refetch } = useQuery<
    GetSystemComponentsByFpsoAreaQueryResult,
    GetSystemComponentsByFpsoAreaQueryVariables
  >(GET_SYSTEM_COMPONENTS_BY_FPSO_AREA, {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
    variables: {
      // fpsoAreaId: fpsoAreaId || "undefined",
      whereVariables: systemComponentsWhereVariables,
    },
    onCompleted: handleSystemComponentsReceived,
  });

  const refetchSystemComponentsFunction = useCallback(() => {
    refetch({
      // fpsoAreaId: fpsoAreaId || "undefined",
      whereVariables: systemComponentsWhereVariables,
    });
  }, [refetch, fpsoAreaId]); // eslint-disable-line react-hooks/exhaustive-deps
  // Disabled warnings here for now as including selectedSubSystemsSubSystemTypeArray as a
  // dependency causes an infinite loop, and other "solutions" for the
  // missing dependencies error for both useCallback and useEffect
  // result in a similar loop or just unexpected behaviour

  useEffect(() => {
    refetchSystemComponentsFunction();
  }, [selectedSubSystems, refetchSystemComponentsFunction]);

  const handleRowAdd = (
    newFpsoAreaComponent: InitializedFpsoAreaSystemComponent,
  ) => {
    if (keycloak?.tokenParsed?.sub) {
      insertFpsoAreaSystemComponent({
        variables: {
          id: uuidv4(),
          fpsoAreaId,
          systemArea: newFpsoAreaComponent.systemArea,
          componentName: newFpsoAreaComponent.componentName,
          quantity: newFpsoAreaComponent.quantity,
          type: newFpsoAreaComponent.type,
          manufacturer: newFpsoAreaComponent.manufacturer,
          modelNo: newFpsoAreaComponent.modelNo,
          serialNo: newFpsoAreaComponent.serialNo,
          description: newFpsoAreaComponent.description,
          comment: newFpsoAreaComponent.comment,
        },
      }).then(() => {
        setFpsoAreaSystemComponentAddFormDisabled();
        refetch({
          // fpsoAreaId: fpsoAreaId || "undefined",
          whereVariables: systemComponentsWhereVariables,
        });
      });
    }
  };

  const handleAddClicked = () => {
    setFpsoAreaSystemComponentAddFormDisabled();
  };

  const handleRowEdit = (
    updatedComponentRow: FpsoAreaSystemComponent,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    updateFpsoAreaSystemComponent({
      variables: {
        id: updatedComponentRow.id,
        systemArea: updatedComponentRow.systemArea,
        componentName: updatedComponentRow.componentName,
        quantity: updatedComponentRow.quantity,
        type: updatedComponentRow.type,
        manufacturer: updatedComponentRow.manufacturer,
        modelNo: updatedComponentRow.modelNo,
        serialNo: updatedComponentRow.serialNo,
        description: updatedComponentRow.description,
        comment: updatedComponentRow.comment,
      },
    })
      .then(() => {
        if (changeInSubSystems === true) {
          deleteSubSystemItem({
            variables: {
              itemId: updatedComponentRow.id,
            },
          });
        } else {
          refetch({
            // fpsoAreaId: fpsoAreaId || "undefined",
            whereVariables: systemComponentsWhereVariables,
          });
        }
      })
      .then(() => {
        if (changeInSubSystems === true) {
          updatedSubSystems.forEach(item => {
            addSubSystemItem({
              variables: {
                itemId: updatedComponentRow.id,
                subSystemType: item,
              },
            }).then(() => {
              refetch({
                // fpsoAreaId: fpsoAreaId || "undefined",
                whereVariables: systemComponentsWhereVariables,
              });
            });
          });
        }
      });
  };

  const handleRowDelete = (deletedComponentRow: FpsoAreaSystemComponent) => {
    deleteFpsoAreaSystemComponent({
      variables: {
        id: deletedComponentRow.id,
      },
    }).then(() => {
      deleteSubSystemItem({
        variables: {
          itemId: deletedComponentRow.id,
        },
      }).then(() => {
        refetch({
          // fpsoAreaId: fpsoAreaId || "undefined",
          whereVariables: systemComponentsWhereVariables,
        });
      });
    });
  };

  // if (insertFpsoAreaSystemComponentError) {
  //   return (
  //     <Grid container>
  //       <Grid item xs={12}>
  //         <Typography variant="body1" color="error">
  //           {t("errors:fpsoAreaRowInsertError")}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  // if (updateFpsoAreaSystemComponentError) {
  //   return (
  //     <Grid container>
  //       <Grid item xs={12}>
  //         <Typography variant="body1" color="error">
  //           {t("errors:fpsoAreaRowUpdateError")}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  // if (mutationResult.error) {
  //   return (
  //     <Grid container>
  //       <Grid item xs={12}>
  //         <Typography variant="body1" color="error">
  //           {t("errors:fpsoAreaRowDeleteError")}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  return (
    <Paper>
      {fpsoAreaSystemComponentAddFormEnabled && (
        <>
          <Grid item xs={12} className={classes.addButtonRow}>
            <Tooltip title={t<string>("tooltipDiscard")}>
              <IconButton onClick={handleAddClicked}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <FpsoAreaSystemComponentAdd
            handleAddFpsoAreaComponent={handleRowAdd}
          />
        </>
      )}
      <MaterialTable
        options={{
          filtering: true,
          draggable: false,
          padding: "default",
          pageSizeOptions: [10, 20, 30],
          pageSize: 10,
          toolbar: true,
          exportButton: true,
          emptyRowsWhenPaging: false,
          detailPanelType: "single",
          rowStyle: rowData => ({
            backgroundColor:
              selectedComponentRow && selectedComponentRow.id === rowData.id
                ? Colors.ITEM_SELECTED
                : "",
          }),
          headerStyle: {
            zIndex: 0,
            fontWeight: "bold",
          },
        }}
        columns={[
          {
            title: t("componentName"),
            field: "componentName",
          },
          {
            title: t("systemArea"),
            field: "systemArea",
            render: rowData => (
              <>
                {rowData.systemArea === SystemArea.other && t("other")}
                {rowData.systemArea === SystemArea.vessel && t("vessel")}
                {rowData.systemArea === SystemArea.electrical &&
                  t("electrical")}
                {rowData.systemArea === SystemArea.instrument &&
                  t("instrument")}
                {rowData.systemArea === SystemArea.mechanical &&
                  t("mechanical")}
                {rowData.systemArea === SystemArea.hardware && t("hardware")}
                {rowData.systemArea === SystemArea.piping && t("piping")}
                {rowData.systemArea === SystemArea.welfare && t("welfare")}
                {rowData.systemArea === SystemArea.process && t("process")}
                {rowData.systemArea === SystemArea.safety && t("safety")}
                {rowData.systemArea === SystemArea.liftingEquipment &&
                  t("liftingEquipment")}
                {rowData.systemArea === SystemArea.wasteManagement &&
                  t("wasteManagement")}
                {rowData.systemArea === SystemArea.chemicalsAndFuel &&
                  t("chemicalsAndFuel")}
              </>
            ),
            lookup: {
              Other: t("other"),
              Vessel: t("vessel"),
              Electrical: t("electrical"),
              Instrument: t("instrument"),
              Mechanical: t("mechanical"),
              Hardware: t("hardware"),
              Piping: t("piping"),
              Welfare: t("welfare"),
              Process: t("process"),
              Safety: t("safety"),
              Lifting_Equipment: t("liftingEquipment"),
              Waste_Management: t("wasteManagement"),
              Chemicals_and_Fuel: t("chemicalsAndFuel"),
            },
          },
          {
            title: t("quantity"),
            field: "quantity",
          },
          {
            title: t("type"),
            field: "type",
          },
          {
            title: t("manufacturer"),
            field: "manufacturer",
          },
          {
            title: t("modelNo"),
            field: "modelNo",
          },
          {
            title: t("serialNo"),
            field: "serialNo",
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedComponentRow(rowData);

          if (togglePanel) {
            togglePanel();
          }
        }}
        icons={TableIcons}
        data={tableData}
        title={fpsoName + " - " + fpsoAreaName + ": " + t("systemDetails")}
        detailPanel={rowData => {
          return (
            <Grid container className={classes.root}>
              <Grid item xs={12}>
                <FpsoAreaSystemComponentDetailsPanel
                  fpsoAreaName={fpsoAreaName}
                  componentRow={rowData}
                  handleRowEdited={handleRowEdit}
                  handleRowDeleted={handleRowDelete}
                />
              </Grid>
            </Grid>
          );
        }}
      />
    </Paper>
  );
};
