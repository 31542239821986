import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import ElementSizes from "Constants/ElementSizes";
import LanguageCodes from "Constants/LanguageCodes";
import { Roles } from "Enums/Roles";
import i18next from "i18next";
import { FPSOArea } from "Models/FPSOArea";
import React, { FC, useCallback, useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactImageGalleryItem } from "react-image-gallery";
import { FileUtils } from "Utils/FileUtils";
import { CarClassRatioChart } from "Views/Components/FpsoOverview/Graphs/CarClassRatioChart";
import { ClosedCarPerformanceIndicatorRatioChart } from "Views/Components/FpsoOverview/Graphs/ClosedCarPerformanceIndicatorRatioChart";
import { CurrentOpenCloseRatioChart } from "Views/Components/FpsoOverview/Graphs/CurrentOpenCloseRatioChart";
import { OpenCarPerformanceIndicatorRatioChart } from "Views/Components/FpsoOverview/Graphs/OpenCarPerformanceIndicatorRatioChart";
import { EditAuditInfoIcon } from "Views/Components/Icons/EditAuditInfoIcon";
import { PictureGallery } from "Views/Components/PictureGallery/PictureGallery";
import { FpsoAreaBasicDetailsEdit } from "./FpsoAreaBasicDetailsEdit";

interface Props {
  readonly fpsoArea: FPSOArea;
  handleFpsoAreaEdited?(updatedFpsoArea: FPSOArea): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    fpsoBasicDetails: {
      marginTop: theme.spacing(4),
    },
    image: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "90%",
      display: "block",
    },
    icon: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: "auto",
      marginRight: "auto",
      width: 15,
    },
    editButtonRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(2),
    },
    readLess: {
      overflow: "hidden",
      height: 390,
      textOverflow: "ellipsis",
      width: "100%",
    },
    buttonText: {
      textTransform: "capitalize",
      fontStyle: "italic",
    },
    multiLine: {
      whiteSpace: "pre-line",
    },
  }),
);

export const FpsoAreaBasicDetails: FC<Props> = ({
  fpsoArea,
  handleFpsoAreaEdited: handleEditedFpsoAreaReceived,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("fpsos");
  const { keycloak } = useKeycloak();

  const [editEnabled, setEditEnabled] = useState(false);
  const [siteDescriptionReadMore, setSiteDescriptionReadMore] = useState(false);

  const handleEditClicked = () => {
    setEditEnabled(!editEnabled);
  };

  const handleFpsoAreaBasicDetailsEdited = (updatedFpsoArea: FPSOArea) => {
    setEditEnabled(false);
    if (handleEditedFpsoAreaReceived) {
      handleEditedFpsoAreaReceived(updatedFpsoArea);
    }
  };

  const handleSiteDescriptionReadMore = () => {
    setSiteDescriptionReadMore(!siteDescriptionReadMore);
  };
  const [fpsoAreaPictures, setFpsoAreaPictures] = useState<
    ReactImageGalleryItem[] | undefined
  >(undefined);

  const loadFpsoAreaPictures = useCallback(async () => {
    const pictureUrls: string[] = [];

    for (const fpsoAreaPicture of fpsoArea.fpsoAreaPictures) {
      const result = await FileUtils.retrieveImageBlobByUrl(
        fpsoAreaPicture.pictureUrl,
        `bearer ${keycloak?.token}`,
      );
      if (result) {
        const binaryData = [] as any;
        binaryData.push(result.data);
        const blob = new Blob(binaryData, {
          type: result.headers["content-type"],
        });
        pictureUrls.push(URL.createObjectURL(blob));
      }
    }

    const imageGalleryItems = pictureUrls.map((pictureUrl, index) => ({
      index,
      original: pictureUrl,
      thumbnail: pictureUrl, // todo: replace with thumbnails generated by backend
    }));
    setFpsoAreaPictures(imageGalleryItems);
  }, [fpsoArea.fpsoAreaPictures, keycloak]);

  useEffect(() => {
    loadFpsoAreaPictures();
  }, [loadFpsoAreaPictures]);

  return (
    <Paper className={classes.root}>
      {editEnabled ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {t("editing") +
              " " +
              fpsoArea.fpso.name +
              " - " +
              fpsoArea.areaName +
              " " +
              t("title")}
          </Typography>
          <Tooltip title={t<string>("cars:tooltipDiscard")}>
            <IconButton onClick={handleEditClicked}>
              <Typography variant="body1">
                {t("cars:discardChanges")}
              </Typography>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : keycloak?.hasRealmRole(Roles.auditor) ||
        keycloak?.hasRealmRole(Roles.administrator) ? (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {fpsoArea.fpso.name + " - " + fpsoArea.areaName + " " + t("title")}
          </Typography>
          <Tooltip
            title={t<string>("fpsos:tooltipEditBasicDetailsFpsoArea")}
            arrow={true}
          >
            <Button onClick={handleEditClicked}>
              <EditAuditInfoIcon />
            </Button>
          </Tooltip>
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.editButtonRow}>
          <Typography variant="h6">
            {fpsoArea.areaName + " " + t("title")}
          </Typography>
        </Grid>
      )}
      {editEnabled ? (
        <FpsoAreaBasicDetailsEdit
          fpsoArea={fpsoArea}
          handleFpsoAreaBasicDetailsEdited={handleFpsoAreaBasicDetailsEdited}
        />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("fpso")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpsoArea.fpso.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("block")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpsoArea.fpso.block.name}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("operator")}
              </Typography>
              <Typography variant="body2" align="center">
                {fpsoArea.fpso.block.owner.companyName}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("anpg_contact")}
              </Typography>

              {fpsoArea.fpso.block.anpgMainContactName ? (
                <Typography variant="body2" align="center">
                  {fpsoArea.fpso.block.anpgMainContactName}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {t("notAvailable")}
                </Typography>
              )}
              {fpsoArea.fpso.block.anpgMainContactEmail == null ? (
                <Typography variant="body2" align="center">
                  {t("emailNotAvailable")}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {fpsoArea.fpso.block.anpgMainContactEmail}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" align="center">
                {t("fpso_area_contact")}
              </Typography>

              {fpsoArea.mainContactName == null ? (
                <Typography variant="body2" align="center">
                  {t("notAvailable")}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {fpsoArea.mainContactName}
                </Typography>
              )}
              {fpsoArea.mainContactEmail == null ? (
                <Typography variant="body2" align="center">
                  {t("emailNotAvailable")}
                </Typography>
              ) : (
                <Typography variant="body2" align="center">
                  {fpsoArea.mainContactEmail}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {t("site_description")}
              </Typography>
              <Typography variant="body2" className={classes.multiLine}>
                {!siteDescriptionReadMore &&
                (fpsoArea.englishTranslation[0]?.fpsoAreaDescription ||
                  fpsoArea.portugueseTranslation[0]?.fpsoAreaDescription) &&
                (fpsoArea.englishTranslation[0]?.fpsoAreaDescription.length ||
                  fpsoArea.portugueseTranslation[0]?.fpsoAreaDescription
                    .length) >
                  ElementSizes.FPSO_DESCRIPTION_READ_MORE_LENGTH ? (
                  <>
                    <Box className={classes.readLess}>
                      {i18next.language === LanguageCodes.ENGLISH
                        ? fpsoArea.englishTranslation[0]?.fpsoAreaDescription ||
                          t("notAvailable")
                        : fpsoArea.portugueseTranslation[0]
                            ?.fpsoAreaDescription || t("notAvailable")}
                    </Box>
                    <Button
                      onClick={handleSiteDescriptionReadMore}
                      className={classes.buttonText}
                    >
                      <Typography variant="body1">
                        {t("audits:readMore")}
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    <Box>
                      {i18next.language === LanguageCodes.ENGLISH
                        ? fpsoArea.englishTranslation[0]?.fpsoAreaDescription ||
                          t("notAvailable")
                        : fpsoArea.portugueseTranslation[0]
                            ?.fpsoAreaDescription || t("notAvailable")}
                    </Box>
                    {siteDescriptionReadMore && (
                      <Button
                        onClick={handleSiteDescriptionReadMore}
                        className={classes.buttonText}
                      >
                        <Typography variant="body1">
                          {t("audits:readLess")}
                        </Typography>
                      </Button>
                    )}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {fpsoArea.fpsoAreaPictures.length > 0 && (
                <div>
                  <Typography variant="subtitle1">
                    {t("site_photos")}
                  </Typography>
                  {fpsoAreaPictures ? (
                    <PictureGallery images={fpsoAreaPictures} />
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
      <Grid container>
        <Grid item xs={12}>
          <CarClassRatioChart fpsoArea={fpsoArea} />
          <CurrentOpenCloseRatioChart fpsoArea={fpsoArea} />
          <ClosedCarPerformanceIndicatorRatioChart fpsoArea={fpsoArea} />
          <OpenCarPerformanceIndicatorRatioChart fpsoArea={fpsoArea} />
        </Grid>
      </Grid>
    </Paper>
  );
};
