export default class Routes {
  public static readonly SYSTEM_DETAILS_BASEPATH = "system-details";
  public static readonly ARCHIVED_AUDITS_BASEPATH = "archived-audits";
  public static readonly CURRENT_AUDIT_BASEPATH = "current-audit";
  public static readonly CURRENT_AUDIT_VIDEO_SESSIONS_BASEPATH =
    "video-sessions";
  public static readonly DOCUMENTS_BASEPATH = "documents";
  public static readonly GRAPHICAL_RESULTS_BASEPATH = "graphical-results";
  public static readonly USER_FEEDBACK_BASEPATH = "user-feedback";
  public static readonly KPI_TABLE_BASEPATH = "actual-audit-status";
  public static readonly MANAGEMENT_SUMMARY_BASEPATH = "management-summary";

  public static readonly HOME = "/";
  public static readonly BLOCK = "/block/:blockId";
  public static readonly FPSO = "/fpso/:fpsoId";
  public static readonly FPSO_AREA = "/fpsoArea/:fpsoAreaId";
  public static readonly SYSTEM_DETAILS = `/fpsoArea/:fpsoAreaId/${Routes.SYSTEM_DETAILS_BASEPATH}/`;
  public static readonly ARCHIVED_AUDITS = `/fpsoArea/:fpsoAreaId/${Routes.ARCHIVED_AUDITS_BASEPATH}/`;
  public static readonly CURRENT_AUDIT = `/fpsoArea/:fpsoAreaId/${Routes.CURRENT_AUDIT_BASEPATH}/`;
  public static readonly CURRENT_AUDIT_VIDEO_SESSIONS = `/fpsoArea/:fpsoAreaId/${Routes.CURRENT_AUDIT_BASEPATH}/${Routes.CURRENT_AUDIT_VIDEO_SESSIONS_BASEPATH}`;
  public static readonly AUDIT = "/fpsoArea/:fpsoAreaId/audit/:auditId";
  public static readonly DOCUMENTS = `/fpsoArea/:fpsoAreaId/${Routes.DOCUMENTS_BASEPATH}/`;
  public static readonly GRAPHICAL_RESULTS = `/${Routes.GRAPHICAL_RESULTS_BASEPATH}`;
  public static readonly USER_FEEDBACK = `/${Routes.USER_FEEDBACK_BASEPATH}`;
  public static readonly KPI_TABLE = `/${Routes.KPI_TABLE_BASEPATH}`;
  public static readonly BI_REPORT = "/bi";
}
