import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { useKeycloak } from "@react-keycloak/web";
import Colors from "Constants/Colors";
import DateConstants from "Constants/DateConstants";
import LanguageCodes from "Constants/LanguageCodes";
import { CarClass } from "Enums/CarClass";
import { CarStatus } from "Enums/CarStatus";
import { PerformanceIndicator } from "Enums/PerformanceIndicator";
import { SubSystemType } from "Enums/SubSystemType";
import {
  GET_CARS_BY_AUDIT,
  GetCarsByAuditQueryResult,
  GetCarsByAuditQueryVariables,
  INSERT_CAR,
  SOFT_DELETE_CAR,
  UPDATE_CAR,
} from "Graphql/CarQueries";
import {
  DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID,
  INSERT_SUB_SYSTEM_ITEM,
} from "Graphql/FpsoQueries";
import i18next from "i18next";
import { convertSelectedSubSystemsToArray } from "Logic/SubSystemOptions/ConvertSelectedSubSystemsToArray";
import MaterialTable from "material-table";
import { Audit } from "Models/Audit";
import { CarRow } from "Models/CarRow";
import { InitializedCar } from "Models/InitialCar";
import { OptionType } from "Models/OptionType";
import { CarTranslation } from "Models/Translations/CarTranslation";
import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiValue } from "react-select";
import { FileUtils } from "Utils/FileUtils";
import { CarDetails } from "Views/Components/CarDetails/CarDetails";
import { CarDetailsAdd } from "Views/Components/CarDetails/CarDetailsAdd";
import { CarEvidenceDocuments } from "Views/Components/CarEvidenceDocuments/CarEvidenceDocuments";
import { BoxIcon } from "Views/Components/Icons/BoxIcon";
import { CircleIcon } from "Views/Components/Icons/CircleIcon";
import { DiamondIcon } from "Views/Components/Icons/DiamondIcon";
import { TableIcons } from "Views/Components/Icons/TableIcons";
import { TriangleIcon } from "Views/Components/Icons/TriangleIcon";

interface Props {
  readonly audit: Audit;
  carAddFormEnabled: boolean;
  setCarAddFormDisabled(): void;
  readonly selectedSubSystems: MultiValue<OptionType> | undefined;
  readonly fpsoAreaName: string | undefined;
  auditLockStatus: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addButtonRow: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(1),
    },
    root: {
      backgroundColor: Colors.ITEM_SELECTED,
    },
    columnHeader: {
      fontWeight: "bold",
    },
  }),
);

export const CarList: FC<Props> = ({
  audit,
  carAddFormEnabled,
  setCarAddFormDisabled,
  selectedSubSystems,
  fpsoAreaName,
  auditLockStatus,
}) => {
  const { t } = useTranslation(["audit", "cars", "errors"]);
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const uuidv4 = require("uuid/v4");

  const [tableData, setTableData] = useState<CarRow[]>([]);
  const [selectedCarRow, setSelectedCarRow] = useState<CarRow>();
  const [carListExporting, setCarListExporting] = useState<boolean>(false);

  const [updateCar, 
    // { error: updateCarError }
  ] = useMutation(UPDATE_CAR);
  const [softDeleteCar, 
    // { error: deleteCarError }
  ] = useMutation(
    SOFT_DELETE_CAR,
  );
  const [insertCar, 
    // { error: insertCarError }
  ] = useMutation(INSERT_CAR);
  const [deleteSubSystemItem] = useMutation(DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID);
  const [addSubSystemItem] = useMutation(INSERT_SUB_SYSTEM_ITEM);

  const handleCarsReceived = () => {
    const data: CarRow[] = [];
    carData?.cars.forEach(item => {
      const carReferenceSplits = item.carReference.split("-");
      const carNumber = carReferenceSplits
        .slice(carReferenceSplits.length - 3)
        .join("-");
      data.push({
        ...item,
        carReference: carNumber,
        addedBy: item.reporter.fullName,
        addedById: item.reporter.id,
        componentsAffected: item.componentsAffected || t("cars:notAvailable"),
        originalCarId: null,
        operatorName: item.fpsoArea.fpso.block.owner.companyName as string,
        englishTranslation:
          (item.englishTranslation[0] as CarTranslation) || [],
        portugueseTranslation:
          (item.portugueseTranslation[0] as CarTranslation) || [],
      });
    });
    setTableData(data);
  };

  const selectedSubSystemsConversion = convertSelectedSubSystemsToArray(
    selectedSubSystems,
  );

  let carListWhereVariables = {};
  if (selectedSubSystemsConversion.selectedSubSystemsString) {
    carListWhereVariables = {
      auditId: { _eq: audit.id },
      items_with_sub_systems: {
        subSystemType: {
          _in:
            selectedSubSystemsConversion.selectedSubSystemsSubSystemTypeArray,
        },
      },
    };
  } else {
    carListWhereVariables = { auditId: { _eq: audit.id } };
  }

  const {
    loading: carsLoading,
    error: carsError,
    data: carData,
    refetch,
  } = useQuery<GetCarsByAuditQueryResult, GetCarsByAuditQueryVariables>(
    GET_CARS_BY_AUDIT,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        whereVariables: carListWhereVariables,
      },
      onCompleted: handleCarsReceived,
    },
  );

  const handleRowAdd = (newCar: InitializedCar) => {
    if (keycloak?.tokenParsed?.sub) {
      insertCar({
        variables: {
          id: uuidv4(),
          class: newCar.class,
          carScore: newCar.score,
          descriptionEnglish: newCar.descriptionEnglish,
          descriptionPortuguese: newCar.descriptionPortuguese,
          reporterId: keycloak?.tokenParsed?.sub,
          auditId: audit.id,
          fpsoAreaId: audit.fpsoAreaId,
          createdAt: newCar.createdAt,
        },
      }).then(() => {
        setCarAddFormDisabled();
        refetch({
          whereVariables: carListWhereVariables,
        });
      });
    }
  };

  const handleRowEdit = (
    updatedCarRow: CarRow,
    updatedSubSystems: SubSystemType[],
    changeInSubSystems: boolean,
  ) => {
    if (auditLockStatus === false) {
      updateCar({
        variables: {
          id: updatedCarRow.id,
          class: updatedCarRow.class,
          status: updatedCarRow.status,
          agreedVerificationDate: updatedCarRow.agreedVerificationDate,
          actualVerificationDate: updatedCarRow.actualVerificationDate,
          affectedSince: updatedCarRow.affectedSince,
          initialAgreedVerificationDate:
            updatedCarRow.initialAgreedVerificationDate,
          carScore: updatedCarRow.carScore,
          componentsAffected: updatedCarRow.componentsAffected,
          systemAreasAffected: updatedCarRow.systemAreasAffected,
          englishTranslationId: updatedCarRow.englishTranslation.id || uuidv4(),
          auditFindingEnglish: updatedCarRow.englishTranslation.auditFinding,
          descriptionEnglish: updatedCarRow.englishTranslation.description,
          furtherDescriptionEnglish:
            updatedCarRow.englishTranslation.furtherDescription,
          portugueseTranslationId:
            updatedCarRow.portugueseTranslation.id || uuidv4(),
          auditFindingPortuguese:
            updatedCarRow.portugueseTranslation.auditFinding,
          descriptionPortuguese:
            updatedCarRow.portugueseTranslation.description,
          furtherDescriptionPortuguese:
            updatedCarRow.portugueseTranslation.furtherDescription,
          createdAt: updatedCarRow.createdAt,
        },
      })
        .then(() => {
          if (changeInSubSystems === true) {
            deleteSubSystemItem({
              variables: {
                itemId: updatedCarRow.id,
              },
            });
          } else {
            refetch({
              whereVariables: carListWhereVariables,
            });
          }
        })
        .then(() => {
          if (changeInSubSystems === true) {
            updatedSubSystems.forEach(item => {
              addSubSystemItem({
                variables: {
                  itemId: updatedCarRow.id,
                  subSystemType: item,
                },
              }).then(() => {
                refetch({
                  whereVariables: carListWhereVariables,
                });
              });
            });
          }
        });
    }
  };

  const handleRowDelete = (deletedCarRow: CarRow) => {
    softDeleteCar({
      variables: {
        id: deletedCarRow.id,
      },
    }).then(() => {
      refetch({
        whereVariables: carListWhereVariables,
      });
    });
  };

  const handleAddClicked = () => {
    setCarAddFormDisabled();
  };

  const handleExportClicked = async () => {
    setCarListExporting(true);
    await FileUtils.downloadCarListExportFile(
      audit.id,
      `bearer ${keycloak?.token}`,
    );
    setCarListExporting(false);
  };

  if (carsError 
    // || insertCarError
    //  || updateCarError || deleteCarError
    ) {
    return (
      <Grid item xs={12}>
        <Typography variant="body1" color="error">
          {t("errors:carsFetchError")}
        </Typography>
      </Grid>
    );
  }

  return (
    <Paper>
      {carAddFormEnabled && (
        <>
          <Grid item xs={12} className={classes.addButtonRow}>
            <Tooltip title={t<string>("cars:tooltipDiscard")}>
              <IconButton onClick={handleAddClicked}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <CarDetailsAdd handleAddCar={handleRowAdd} />
        </>
      )}
      <MaterialTable
        isLoading={carsLoading || carListExporting}
        options={{
          draggable: false,
          padding: "default",
          pageSizeOptions: [10, 20, 30],
          pageSize: 10,
          toolbar: true,
          exportButton: true,
          exportCsv: (columns, data) => {
            handleExportClicked();
          },
          emptyRowsWhenPaging: false,
          detailPanelType: "single",
          rowStyle: rowData => ({
            backgroundColor:
              selectedCarRow && selectedCarRow.id === rowData.id
                ? Colors.ITEM_SELECTED
                : "",
            textDecoration: rowData.isDeleted ? "line-through" : "",
            color: rowData.isDeleted ? "rgba(0, 0, 0, 0.38)" : "",
          }),
          headerStyle: {
            zIndex: 0,
            fontWeight: "bold",
          },
        }}
        columns={[
          {
            title: t("cars:carReference"),
            field: "carReference",
          },
          {
            title: t("cars:description"),
            field:
              i18next.language === LanguageCodes.ENGLISH
                ? "englishTranslation.description"
                : "portugueseTranslation.description",
          },
          {
            title: t("cars:class"),
            field: "class",
            render: rowData => (
              <>
                {rowData.class === CarClass.comment && t("cars:classComment")}
                {rowData.class === CarClass.minor && t("cars:classMinor")}
                {rowData.class === CarClass.major && t("cars:classMajor")}
                {rowData.class === CarClass.critical && t("cars:classCritical")}
              </>
            ),
          },
          {
            title: t("cars:status"),
            field: "status",
            render: rowData => (
              <>
                {rowData.status === CarStatus.open && t("cars:statusOpen")}
                {rowData.status === CarStatus.closed && t("cars:statusClosed")}
              </>
            ),
          },
          {
            title: t("cars:actionRequired"),
            field: "actionRequired",
            render: rowData => (
              <>
                <div>
                  {rowData.operatorActionRequired && rowData.operatorName}
                </div>
                <div>{rowData.anpgActionRequired && t("cars:anpgName")}</div>
                <div>{rowData.auditorActionRequired && t("cars:ogcName")}</div>
              </>
            ),
          },
          {
            title: t("cars:performanceIndicator"),
            field: "performanceIndicator",
            render: rowData => (
              <>
                {rowData.performanceIndicator ===
                  PerformanceIndicator.yellow && (
                  <Tooltip
                    title={t<string>("cars:yellowCircleTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <CircleIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_YELLOW}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.blue && (
                  <Tooltip
                    title={t<string>("cars:blueSquareTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <BoxIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_BLUE}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.red && (
                  <Tooltip
                    title={t<string>("cars:redTriangleTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <TriangleIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_RED}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.performanceIndicator === PerformanceIndicator.grey && (
                  <Tooltip
                    title={t<string>("cars:greyDiamondTooltip")}
                    placement="bottom"
                    arrow={true}
                  >
                    <span>
                      <DiamondIcon
                        strokeColor={Colors.PERFORMANCE_INDICATOR_GREY}
                        fillColor={"transparent"}
                      />
                    </span>
                  </Tooltip>
                )}
                {rowData.status === CarStatus.closed && (
                  <CheckIcon htmlColor={Colors.CAR_CLOSED} />
                )}
              </>
            ),
          },
          {
            title: t("cars:dateAdded"),
            field: "createdAt",
            type: "date",
            render: rowData => (
              <>
                {rowData.createdAt ? (
                  <>
                    {moment(rowData.createdAt).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
          {
            title: t("cars:addedBy"),
            field: "addedBy",
          },
          {
            title: t("cars:agreedVerificationDate"),
            field: "agreedVerificationDate",
            type: "date",
            render: rowData => (
              <>
                {rowData.agreedVerificationDate ? (
                  <>
                    {moment(rowData.agreedVerificationDate).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
          {
            title: t("cars:actualVerificationDate"),
            field: "actualVerificationDate",
            type: "date",
            render: rowData => (
              <>
                {rowData.actualVerificationDate ? (
                  <>
                    {moment(rowData.actualVerificationDate).format(
                      DateConstants.DEFAULT_DATE_FORMAT,
                    )}
                  </>
                ) : (
                  <>{t("cars:notAvailable")}</>
                )}
              </>
            ),
          },
        ]}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedCarRow(rowData);

          if (togglePanel) {
            togglePanel();
          }
        }}
        icons={TableIcons}
        data={tableData}
        title={audit.auditReference}
        detailPanel={rowData => {
          return (
            <Grid container className={classes.root}>
              <Grid item xs={6}>
                <CarDetails
                  carRow={rowData}
                  isActive={audit.isActive}
                  isLocked={audit.isLocked}
                  handleRowEdited={handleRowEdit}
                  handleRowDeleted={handleRowDelete}
                  fpsoAreaName={fpsoAreaName}
                />
              </Grid>
              <Grid item xs={6}>
                <CarEvidenceDocuments
                  carRow={rowData}
                  fpsoId={audit.fpsoId}
                  fpsoAreaId={audit.fpsoAreaId}
                  auditId={audit.id}
                  isActive={true}
                  isLocked={audit.isLocked}
                  isArchived={false}
                />
              </Grid>
            </Grid>
          );
        }}
        localization={{
          toolbar: {
            exportTitle: "Export to Excel"
          }
        }}
      />
    </Paper>
  );
};
