import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useKeycloak } from "@react-keycloak/web";
import OGCLogoWhite from "Assets/Images/OGCLogoWhite.png";
import clsx from "clsx";
import Colors from "Constants/Colors";
import ElementSizes from "Constants/ElementSizes";
import LanguageCodes from "Constants/LanguageCodes";
import Routes from "Constants/Routes";
import { Environment } from "Enums/Environment";
import { Roles } from "Enums/Roles";
import i18n from "i18n";
import i18next from "i18next";
import { KeycloakProfile } from "keycloak-js";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AngolianFlagIcon } from "Views/Components/Icons/AngolianFlagIcon";
import { BritishFlagIcon } from "Views/Components/Icons/BritishFlagIcon";

interface Props {
  onDrawerToggle: () => void;
  drawerOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    headerDev: {
      backgroundColor: Colors.HEADER_DEV,
    },
    headerAcc: {
      backgroundColor: Colors.HEADER_ACC,
    },
    headerShift: {
      width: `calc(100% - ${ElementSizes.DRAWER_WIDTH}px)`,
      marginLeft: ElementSizes.DRAWER_WIDTH,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    motto: {
      flexGrow: 1,
      paddingLeft: theme.spacing(1),
    },
    profileSection: {
      justifyContent: "space-between",
      display: "flex",
      paddingBottom: theme.spacing(2),
    },
    image: {
      height: ElementSizes.OGC_LOGO_HEIGHT,
      padding: theme.spacing(1),
      display: "block",
    },
  }),
);

export const Header: FC<Props> = ({ onDrawerToggle, drawerOpen }) => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation("menu");
  const history = useHistory();

  const [userProfile, setUserProfile] = useState<KeycloakProfile | undefined>(
    undefined,
  );

  const loadUserProfile = useCallback(async () => {
    try {
      const response = await keycloak?.loadUserProfile();
      setUserProfile(response);
    } catch (error) {
      keycloak?.logout();
    }
  }, [keycloak]);

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  const changeLanguage = (language: string) => {
    if (language !== i18next.language) {
      i18n.changeLanguage(language);
    }
  };
  const handleHomeClick = () => {
    history.push(Routes.HOME);
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(
        classes.header,
        process.env.REACT_APP_ENVIRONMENT_NAME === Environment.development &&
          classes.headerDev,
        process.env.REACT_APP_ENVIRONMENT_NAME === Environment.acceptance &&
          classes.headerAcc,
        {
          [classes.headerShift]: drawerOpen,
        },
      )}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={() => onDrawerToggle()}
        >
          {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Tooltip title={t<string>("ogcWebsite")} arrow={true}>
          <a
            href="http://www.ogcgreenworld.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className={classes.image} src={OGCLogoWhite} alt="OGC Logo" />
          </a>
        </Tooltip>
        <Typography variant="h6">
          {process.env.REACT_APP_ENVIRONMENT_NAME === Environment.development
            ? t("environmentDev") + " - "
            : process.env.REACT_APP_ENVIRONMENT_NAME ===
                Environment.acceptance &&
              t("environmentAcc") + " - " + t("title") + " |"}
        </Typography>
        <Typography variant="h4" className={classes.motto}>
          {t("ogcMotto")}
        </Typography>
        {!keycloak?.hasRealmRole(Roles.biUser) && 
        <>
          <Button color="inherit" onClick={() => handleHomeClick()}>
            {t("home")}
          </Button>
          <Tooltip title={t<string>("englishButton")} arrow={true}>
            <Button
              color="inherit"
              onClick={() => changeLanguage(LanguageCodes.ENGLISH)}
            >
              <BritishFlagIcon />
            </Button>
          </Tooltip>
          <Tooltip title={t<string>("portugueseButton")} arrow={true}>
            <Button
              color="inherit"
              onClick={() => changeLanguage(LanguageCodes.PORTUGUESE)}
            >
              <AngolianFlagIcon />
            </Button>
          </Tooltip>
        </>
        }
        {userProfile && (
          <Button color="inherit" onClick={() => keycloak?.logout()}>
            {t("logout") + userProfile?.firstName}
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
