import { useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
} from "@material-ui/core";
import FPSO_background from "Assets/Images/FPSOGettyImage_1.jpg";
import FPSO_background_blackwhite from "Assets/Images/FPSOGettyImage_1_blackwhite.jpg";
import clsx from "clsx";
import ElementSizes from "Constants/ElementSizes";
import Routes from "Constants/Routes";
import { Roles } from "Enums/Roles";
import { GET_BLOCKS, GetBlocksQueryResult } from "Graphql/BlockQueries";
import { keycloak } from "keycloak";
import React, { FC, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ArchivedAudits } from "Views/Components/ArchivedAudits/ArchivedAudits";
import { ArchivedAuditOverview } from "Views/Components/AuditOverview/ArchivedAuditOverview";
import { AuditVideoSessionList } from "Views/Components/AuditOverview/AuditVideoSessionList";
import { CurrentAuditOverview } from "Views/Components/AuditOverview/CurrentAuditOverview";
import { BlockTreeView } from "Views/Components/BlockTreeView/BlockTreeView";
import { FpsoAreaDocuments } from "Views/Components/FpsoAreaDocuments/FpsoAreaDocuments";
import { FpsoAreaOverview } from "Views/Components/FpsoOverview/FpsoArea/FpsoAreaOverview";
import { FpsoAreaSystemDetailsOverview } from "Views/Components/FpsoOverview/FpsoArea/FpsoAreaSystemDetailsOverview";
import { FpsoOverview } from "Views/Components/FpsoOverview/FpsoOverview";
import { Header } from "Views/Components/Header/Header";
import { BiReport } from "Views/Components/HomePage/BiReport";
import { BlocksMap } from "Views/Components/HomePage/BlocksMap";
import { DetailedBlockMap } from "Views/Components/HomePage/DetailedBlockMap";
import { GraphicalResults } from "Views/Components/ManagementSummary/GraphicalResults";
import { KPILeagueTable } from "Views/Components/ManagementSummary/KPILeagueTable";
import { UserFeedback } from "Views/Components/UserFeedback/UserFeedback";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundImage: `url(${FPSO_background})`,
      minHeight: "100vh",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      overflow: "auto",
      backgroundRepeat: "no-repeat",
    },
    backgroundContainer: {
      backgroundImage: `url(${FPSO_background_blackwhite})`,
      minHeight: "100vh",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      overflow: "auto",
      backgroundRepeat: "no-repeat",
    },
    drawer: {
      width: ElementSizes.DRAWER_WIDTH,
      flexShrink: 0,
    },
    headerSpacing: {
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -ElementSizes.DRAWER_WIDTH,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

export const MainPage: FC = () => {
  const classes = useStyles();
  const isBiUser = keycloak?.hasRealmRole(Roles.biUser);
  
  const { loading: blocksLoading, error: blocksError, data: blocks } = useQuery<
    GetBlocksQueryResult
  >(GET_BLOCKS);

  const [drawerOpen, setDrawerOpen] = useState(!isBiUser);

  const [lastVisitedArchivedAuditId, setLastVisitedArchivedAuditId] = useState<
    string
  >("");

  if (blocksError && !isBiUser) {
    return <div>{blocksError}</div>;
  }

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Router>
      <div className={classes.root}>
        <Header onDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen} />
        <Drawer
          variant="persistent"
          anchor="left"
          className={classes.drawer}
          open={drawerOpen}
        >
          {blocksLoading ? (
            <CircularProgress />
          ) : (
            <div>
              {blocks && blocks.blocks ? (
                <BlockTreeView blocks={blocks.blocks} />
              ) : (
                <div>No blocks found</div>
              )}
            </div>
          )}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: drawerOpen,
          })}
        >
          <div className={classes.headerSpacing} />
          <Switch>
            <Route exact path={Routes.HOME}>
            { 
              (blocksError && isBiUser) ? <BiReport /> : <BlocksMap />
            }
            </Route>
            <Route exact path={Routes.BLOCK}>
              <DetailedBlockMap />
            </Route>
            <Route exact path={Routes.FPSO}>
              <FpsoOverview />
            </Route>
            <Route exact path={Routes.FPSO_AREA}>
              <FpsoAreaOverview />
            </Route>
            <Route exact path={Routes.ARCHIVED_AUDITS}>
              <ArchivedAudits
                lastVisitedArchivedAuditId={lastVisitedArchivedAuditId}
              />
            </Route>
            <Route exact path={Routes.CURRENT_AUDIT}>
              <CurrentAuditOverview />
            </Route>
            <Route exact path={Routes.CURRENT_AUDIT_VIDEO_SESSIONS}>
              <AuditVideoSessionList />
            </Route>
            <Route exact path={Routes.SYSTEM_DETAILS}>
              <FpsoAreaSystemDetailsOverview />
            </Route>
            <Route exact path={Routes.AUDIT}>
              <div className={classes.backgroundContainer}>
                <ArchivedAuditOverview
                  setLastVisitedArchivedAuditId={setLastVisitedArchivedAuditId}
                />
              </div>
            </Route>
            <Route exact path={Routes.DOCUMENTS}>
              <FpsoAreaDocuments />
            </Route>
            <Route exact path={Routes.GRAPHICAL_RESULTS}>
              <GraphicalResults />
            </Route>
            <Route exact path={Routes.KPI_TABLE}>
              <KPILeagueTable />
            </Route>
            <Route exact path={Routes.USER_FEEDBACK}>
              <UserFeedback />
            </Route>
            <Route exact path={Routes.BI_REPORT}>
              <BiReport />
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
};
