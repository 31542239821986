import { gql } from "@apollo/client";
import { ActionRequiredRatio } from "Models/ActionRequiredRatio";
import { FPSO } from "Models/FPSO";
import { FPSOArea } from "Models/FPSOArea";
import { FpsoAreaSystemComponent } from "Models/FpsoAreaSystemComponent";

export const GET_FPSO_AND_AREAS_BY_ID = gql`
  query getFpsoById($id: uuid!) {
    fpso: fpsos_by_pk(id: $id) {
      id
      name
      englishTranslation: fpso_description_translations(
        where: { languageCode: { _eq: en } }
      ) {
        id
        fpsoDescription
      }
      portugueseTranslation: fpso_description_translations(
        where: { languageCode: { _eq: pt } }
      ) {
        id
        fpsoDescription
      }
      latitude
      longitude
      startOfOperations
      fpsoMainContactName
      fpsoMainContactEmail
      block {
        id
        name
        owner {
          companyId
          companyName
          employees: users(where: { stillEmployed: { _eq: true } }) {
            fullName
            id
            userContactInfo {
              email
              phoneNo
            }
          }
        }
        anpgMainContactName
        anpgMainContactEmail
      }

      fpsoAreas: fpso_areas {
        id
        mainContactEmail
        mainContactName
        updatedAt
        createdAt
        currentAuditId
        areaName

        englishTranslation: fpso_area_description_translations(
          where: { languageCode: { _eq: en } }
        ) {
          createdAt
          fpsoAreaDescription
          fpsoAreaId
          id
          languageCode
          updatedAt
        }

        portugueseTranslation: fpso_area_description_translations(
          where: { languageCode: { _eq: pt } }
        ) {
          createdAt
          fpsoAreaDescription
          fpsoAreaId
          id
          languageCode
          updatedAt
        }

        fpsoAreaPictures: fpso_area_pictures {
          id
          pictureUrl
          thumbUrl
          pictureDescription
          createdAt
          itemsWithSubSystems: items_with_sub_systems {
            createdAt
            id
            itemId
            subSystemType
            updatedAt
          }
        }
        allDocuments: fpso_area_documents {
          id
          type
          name
          description
          reference
          publishDate
          systemArea
          itemsWithSubSystems: items_with_sub_systems {
            createdAt
            id
            itemId
            subSystemType
            updatedAt
          }
        }
      }
    }
  }
`;

export interface GetFpsoAndAreasByIdQueryResult {
  fpso: FPSO;
}

export interface GetFpsoAndAreasByIdQueryVariables {
  id: string;
}

export const GET_FPSO_AREA_BY_ID = gql`
  query getFpsoAreaById($id: uuid!) {
    fpsoArea: fpso_areas_by_pk(id: $id) {
      id
      areaName
      updatedAt
      createdAt
      currentAuditId
      mainContactName
      mainContactEmail
      fpso {
        id
        createdAt
        fpsoMainContactEmail
        fpsoMainContactName
        latitude
        longitude
        name
        startOfOperations
        updatedAt
        englishTranslation: fpso_description_translations(
          where: { languageCode: { _eq: en } }
        ) {
          createdAt
          fpsoDescription
          fpsoId
          id
          languageCode
          updatedAt
        }

        portugueseTranslation: fpso_description_translations(
          where: { languageCode: { _eq: pt } }
        ) {
          createdAt
          fpsoDescription
          fpsoId
          id
          languageCode
          updatedAt
        }
        block {
          id
          name
          owner {
            companyId
            companyName
            employees: users(where: { stillEmployed: { _eq: true } }) {
              fullName
              id
              userContactInfo {
                email
                phoneNo
              }
            }
          }
          anpgMainContactName
          anpgMainContactEmail
        }
      }
      englishTranslation: fpso_area_description_translations(
        where: { languageCode: { _eq: en } }
      ) {
        createdAt
        fpsoAreaDescription
        fpsoAreaId
        id
        languageCode
        updatedAt
      }

      portugueseTranslation: fpso_area_description_translations(
        where: { languageCode: { _eq: pt } }
      ) {
        createdAt
        fpsoAreaDescription
        fpsoAreaId
        id
        languageCode
        updatedAt
      }

      fpsoAreaPictures: fpso_area_pictures {
        id
        pictureUrl
        thumbUrl
        pictureDescription
        createdAt
        itemsWithSubSystems: items_with_sub_systems {
          createdAt
          id
          itemId
          subSystemType
          updatedAt
        }
      }
      allDocuments: fpso_area_documents {
        id
        type
        name
        description
        reference
        publishDate
        systemArea
        itemsWithSubSystems: items_with_sub_systems {
          createdAt
          id
          itemId
          subSystemType
          updatedAt
        }
      }
    }
  }
`;

export interface GetFpsoAreaByIdQueryResult {
  fpsoArea: FPSOArea;
}

export interface GetFpsoAreaByIdQueryVariables {
  id: string;
}

export const GET_FPSO_AREA_DOCUMENTS_BY_ID = gql`
  query getFpsoAreaDocumentsById(
    $id: uuid!
    $whereVariables: fpso_area_documents_bool_exp
  ) {
    fpsoArea: fpso_areas_by_pk(id: $id) {
      id
      areaName
      fpso {
        id
        name
      }
      fpsoAreaDocuments: fpso_area_documents(where: $whereVariables) {
        id
        type
        name
        documentNumber
        revision
        description
        reference
        publishDate
        systemArea
        comment
        uploader {
          id
          fullName
        }
        itemsWithSubSystems: items_with_sub_systems {
          createdAt
          id
          itemId
          subSystemType
          updatedAt
        }
      }
    }
  }
`;

export interface GetFpsoAreaDocumentsByIdQueryResult {
  fpsoArea: FPSOArea;
}

export interface GetFpsoAreaDocumentsByIdQueryVariables {
  id: string;
  whereVariables: object;
}

export const UPDATE_FPSO = gql`
  mutation updateFpso(
    $id: uuid!
    $latitude: String
    $longitude: String
    $name: String
    $startOfOperations: date
    $anpgContact: String
    $blockId: uuid!
    $anpgMainContactName: String
    $fpsoMainContactName: String
    $anpgMainContactEmail: String
    $fpsoMainContactEmail: String
    $fpsoEnglishDescription: String
    $fpsoPortugueseDescription: String
  ) {
    update_fpsos(
      where: { id: { _eq: $id } }
      _set: {
        latitude: $latitude
        longitude: $longitude
        name: $name
        startOfOperations: $startOfOperations
        fpsoMainContactName: $fpsoMainContactName
        fpsoMainContactEmail: $fpsoMainContactEmail
      }
    ) {
      returning {
        id
      }
    }
    update_blocks(
      where: { id: { _eq: $blockId } }
      _set: {
        anpgMainContactName: $anpgMainContactName
        anpgMainContactEmail: $anpgMainContactEmail
      }
    ) {
      returning {
        id
      }
    }
    updateEnglishDescription: update_fpso_description_translations(
      where: { fpsoId: { _eq: $id }, languageCode: { _eq: en } }
      _set: { fpsoDescription: $fpsoEnglishDescription }
    ) {
      returning {
        id
      }
    }
    updatePortugueseDescription: update_fpso_description_translations(
      where: { fpsoId: { _eq: $id }, languageCode: { _eq: pt } }
      _set: { fpsoDescription: $fpsoPortugueseDescription }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_FPSO_AREA = gql`
  mutation updateFpsoArea(
    $id: uuid!
    $areaName: String
    $mainContactEmail: String
    $mainContactName: String
    $fpsoAreaEnglishDescription: String
    $fpsoAreaPortugueseDescription: String
  ) {
    update_fpso_areas(
      where: { id: { _eq: $id } }
      _set: {
        mainContactEmail: $mainContactEmail
        mainContactName: $mainContactName
      }
    ) {
      returning {
        id
      }
    }
    updateEnglishDescription: update_fpso_area_description_translations(
      where: { fpsoAreaId: { _eq: $id }, languageCode: { _eq: en } }
      _set: { fpsoAreaDescription: $fpsoAreaEnglishDescription }
    ) {
      returning {
        id
      }
    }
    updatePortugueseDescription: update_fpso_area_description_translations(
      where: { fpsoAreaId: { _eq: $id }, languageCode: { _eq: pt } }
      _set: { fpsoAreaDescription: $fpsoAreaPortugueseDescription }
    ) {
      returning {
        id
      }
    }
  }
`;

export const INSERT_FPSO_AREA_SYSTEM_COMPONENT = gql`
  mutation insertFpsoAreaSystemComponent(
    $id: uuid!
    $fpsoAreaId: uuid!
    $componentName: String
    $manufacturer: String
    $modelNo: String
    $quantity: String
    $type: String
    $serialNo: String
    $description: String
    $comment: String
    $systemArea: system_areas_enum
  ) {
    result: insert_fpso_area_system_components(
      objects: {
        id: $id
        fpsoAreaId: $fpsoAreaId
        componentName: $componentName
        manufacturer: $manufacturer
        modelNo: $modelNo
        quantity: $quantity
        type: $type
        serialNo: $serialNo
        description: $description
        comment: $comment
        systemArea: $systemArea
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_FPSO_AREA_SYSTEM_COMPONENT = gql`
  mutation updateFpsoAreaSystemComponent(
    $id: uuid!
    $systemArea: system_areas_enum
    $componentName: String
    $quantity: String
    $type: String
    $manufacturer: String
    $modelNo: String
    $serialNo: String
    $description: String
    $comment: String
  ) {
    update_fpso_area_system_components(
      where: { id: { _eq: $id } }
      _set: {
        systemArea: $systemArea
        componentName: $componentName
        quantity: $quantity
        type: $type
        manufacturer: $manufacturer
        modelNo: $modelNo
        serialNo: $serialNo
        description: $description
        comment: $comment
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_FPSO_AREA_SYSTEM_COMPONENT = gql`
  mutation deleteFpsoAreaSystemComponent($id: uuid!) {
    result: delete_fpso_area_system_components(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_FPSO_AREA_DOCUMENT = gql`
  mutation insertFpsoAreaDocument(
    $type: document_type_enum
    $name: String
    $documentNumber: String
    $revision: String
    $description: String
    $reference: String
    $systemArea: system_areas_enum
    $fpsoAreaId: uuid!
    $uploaderId: uuid!
    $publishDate: date
  ) {
    result: insert_fpso_area_documents(
      objects: {
        type: $type
        name: $name
        documentNumber: $documentNumber
        revision: $revision
        description: $description
        reference: $reference
        systemArea: $systemArea
        fpsoAreaId: $fpsoAreaId
        uploaderId: $uploaderId
        publishDate: $publishDate
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_FPSO_AREA_DOCUMENT = gql`
  mutation deleteFpsoAreaDocument($id: uuid!) {
    result: delete_fpso_area_documents(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_FPSO_AREA_DOCUMENT = gql`
  mutation updateFpsoAreaDocument(
    $id: uuid!
    $comment: String
    $description: String
    $documentNumber: String
    $name: String
    $publishDate: date
    $reference: String
    $revision: String
    $systemArea: system_areas_enum
    $type: document_type_enum
  ) {
    update_fpso_area_documents(
      where: { id: { _eq: $id } }
      _set: {
        comment: $comment
        description: $description
        documentNumber: $documentNumber
        name: $name
        publishDate: $publishDate
        reference: $reference
        revision: $revision
        systemArea: $systemArea
        type: $type
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const GET_CAR_CLASS_RATIO_BY_FPSO_AREA = gql`
  query getCarClassRatioByFpsoArea($fpsoAreaId: uuid!) {
    carClassRatios: get_car_class_ratio(args: { p_fpso_area_id: $fpsoAreaId }) {
      class
      amount
    }
  }
`;

export interface GetCarClassRatioByFpsoAreaResult {
  carClassRatios: Array<{
    class: string;
    amount: number;
  }>;
}

export interface GetCarClassRatioByFpsoAreaVariables {
  fpsoAreaId: string;
}

export const GET_CAR_STATUS_RATIO_BY_FPSO_AREA = gql`
  query getCarStatusRatioByFpsoArea($fpsoAreaId: uuid!) {
    carStatusRatios: get_car_status_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      status
      amount
    }

    carAnpgActionRequiredRatio: get_car_anpg_action_required_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      action_required
      amount
    }

    carAuditorActionRequiredRatio: get_car_auditor_action_required_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      action_required
      amount
    }

    carOperatorActionRequiredRatio: get_car_operator_action_required_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      action_required
      amount
    }
  }
`;

export interface GetCarStatusRatioByFpsoAreaResult {
  carStatusRatios: Array<{
    status: string;
    amount: number;
  }>;
  carAnpgActionRequiredRatio: ActionRequiredRatio[];
  carAuditorActionRequiredRatio: ActionRequiredRatio[];
  carOperatorActionRequiredRatio: ActionRequiredRatio[];
}

export interface GetCarStatusRatioByFpsoAreaVariables {
  fpsoAreaId: string;
}

export const GET_CLOSED_CAR_PERFORMANCE_INDICATOR_RATIO_BY_FPSO_AREA = gql`
  query getClosedCarPerformanceIndicatorRatioByFpsoArea($fpsoAreaId: uuid!) {
    closedCarPerformanceIndicatorRatios: get_closed_car_performance_indicator_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      performance_indicator
      amount
    }
  }
`;

export interface GetClosedCarPerformanceIndicatorRatioByFpsoAreaResult {
  closedCarPerformanceIndicatorRatios: Array<{
    performance_indicator: string;
    amount: number;
  }>;
}

export interface GetClosedCarPerformanceIndicatorRatioByFpsoAreaVariables {
  fpsoAreaId: string;
}

export const GET_OPEN_CAR_PERFORMANCE_INDICATOR_RATIO_BY_FPSO_AREA = gql`
  query getOpenCarPerformanceIndicatorRatioByFpsoArea($fpsoAreaId: uuid!) {
    openCarPerformanceIndicatorRatios: get_open_car_performance_indicator_ratio(
      args: { p_fpso_area_id: $fpsoAreaId }
    ) {
      performance_indicator
      amount
    }
  }
`;

export interface GetOpenCarPerformanceIndicatorRatioByFpsoAreaResult {
  openCarPerformanceIndicatorRatios: Array<{
    performance_indicator: string;
    amount: number;
  }>;
}

export interface GetOpenCarPerformanceIndicatorRatioByFpsoAreaVariables {
  fpsoAreaId: string;
}

export const GET_ALL_FPSOS_SCORE_OVER_TIME = gql`
  query getAllFpsosScoreOverTime {
    fpsos(order_by: { block: { name: asc } }) {
      id
      name
      block {
        owner {
          companyName
        }
      }
      fpsoAreas: fpso_areas {
        id
        updatedAt
        createdAt
        currentAuditId
        areaName
        currentAudit: current_audit {
          auditScore
          createdAt
          auditReference
        }
        auditScoresOverTime: audit_snapshots(order_by: { createdAt: asc }) {
          auditScore
          createdAt
          auditReference
        }
      }
    }
  }
`;

export interface GetAllFpsosScoreOverTimeResult {
  fpsos: FPSO[];
}

export const GET_FPSO_LEAGUE_TABLE_DATA = gql`
  query getFpsoLeagueTableData {
    fpsos {
      id
      name
      block {
        owner {
          companyName
        }
      }
      fpsoAreas: fpso_areas(
        order_by: { current_audit: { auditScore: desc } }
        where: { currentAuditId: { _is_null: false } }
      ) {
        id
        updatedAt
        createdAt
        currentAuditId
        areaName
        fpso {
          id
          name
          block {
            owner {
              companyName
            }
          }
        }
        currentAuditCarClassesCount: current_audit {
          auditScore
          startDate
          countComments: cars_aggregate(
            where: {
              isDeleted: { _eq: false }
              status: { _neq: CLOSED }
              class: { _eq: Comment }
            }
          ) {
            aggregate {
              count
            }
          }
          countMinors: cars_aggregate(
            where: {
              isDeleted: { _eq: false }
              status: { _neq: CLOSED }
              class: { _eq: Minor }
            }
          ) {
            aggregate {
              count
            }
          }
          countMajors: cars_aggregate(
            where: {
              isDeleted: { _eq: false }
              status: { _neq: CLOSED }
              class: { _eq: Major }
            }
          ) {
            aggregate {
              count
            }
          }
          countCriticals: cars_aggregate(
            where: {
              isDeleted: { _eq: false }
              status: { _neq: CLOSED }
              class: { _eq: Critical }
            }
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;

export interface GetFpsoLeagueTableDataResult {
  fpsos: FPSO[];
}

export const INSERT_FPSO_AREA_PICTURE = gql`
  mutation insertFpsoAreaPicture(
    $fpsoAreaId: uuid!
    $pictureUrl: String
    $thumbUrl: String
    $pictureDescription: String
  ) {
    result: insert_fpso_area_pictures(
      objects: {
        fpsoAreaId: $fpsoAreaId
        pictureUrl: $pictureUrl
        thumbUrl: $thumbUrl
        pictureDescription: $pictureDescription
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_FPSO_AREA_PICTURE = gql`
  mutation deleteFpsoAreaPicture($id: uuid!) {
    result: delete_fpso_area_pictures(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const GET_SYSTEM_COMPONENTS_BY_FPSO_AREA = gql`
  query getSystemComponentsByFpsoArea(
    # $fpsoAreaId: uuid!
    $whereVariables: fpso_area_system_components_bool_exp
  ) {
    fpsoAreaSystemComponents: fpso_area_system_components(
      where: $whereVariables
      order_by: { createdAt: asc }
    ) {
      comment
      componentName
      createdAt
      description
      fpsoAreaId
      id
      manufacturer
      modelNo
      quantity
      serialNo
      systemArea
      type
      updatedAt
      itemsWithSubSystems: items_with_sub_systems {
        createdAt
        id
        itemId
        subSystemType
        updatedAt
      }
    }
  }
`;

export interface GetSystemComponentsByFpsoAreaQueryResult {
  fpsoAreaSystemComponents: FpsoAreaSystemComponent[];
}

export interface GetSystemComponentsByFpsoAreaQueryVariables {
  // fpsoAreaId: string;
  whereVariables: object;
}

export const DELETE_SUB_SYSTEM_ITEMS_BY_ITEM_ID = gql`
  mutation deleteSubSystemItemsByItemId($itemId: uuid!) {
    delete_items_with_sub_systems(where: { itemId: { _eq: $itemId } }) {
      affected_rows
    }
  }
`;

export const INSERT_SUB_SYSTEM_ITEM = gql`
  mutation addSubSystemItem(
    $itemId: uuid!
    $subSystemType: sub_system_type_enum!
  ) {
    insert_items_with_sub_systems(
      objects: { itemId: $itemId, subSystemType: $subSystemType }
    ) {
      returning {
        id
        subSystemType
      }
    }
  }
`;
