import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import {
  GET_FPSO_AND_AREAS_BY_ID,
  GetFpsoAndAreasByIdQueryResult,
  GetFpsoAndAreasByIdQueryVariables,
  UPDATE_FPSO,
} from "Graphql/FpsoQueries";
import { FPSO } from "Models/FPSO";
import { RouteParams } from "Models/RouteParams";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FpsoBasicDetails } from "Views/Components/FpsoOverview/FpsoBasicDetails";

export const FpsoOverview: FC = () => {
  const { fpsoId } = useParams<RouteParams>();
  const { t } = useTranslation();

  const {
    loading: fpsoLoading,
    error: fpsoError,
    data: fpsoInfo,
    refetch,
  } = useQuery<
    GetFpsoAndAreasByIdQueryResult,
    GetFpsoAndAreasByIdQueryVariables
  >(GET_FPSO_AND_AREAS_BY_ID, { variables: { id: fpsoId || "undefined" } });
  const [updateFpso] = useMutation(UPDATE_FPSO);

  const handleFpsoEdit = (updatedFpso: FPSO) => {
    updateFpso({
      variables: {
        id: updatedFpso.id,
        latitude: updatedFpso.latitude,
        longitude: updatedFpso.longitude,
        name: updatedFpso.name,
        startOfOperations: updatedFpso.startOfOperations,
        blockId: updatedFpso.block.id,
        anpgMainContactName: updatedFpso.block.anpgMainContactName,
        fpsoMainContactName: updatedFpso.fpsoMainContactName,
        anpgMainContactEmail: updatedFpso.block.anpgMainContactEmail,
        fpsoMainContactEmail: updatedFpso.fpsoMainContactEmail,
        fpsoEnglishDescription:
          updatedFpso.englishTranslation[0].fpsoDescription,
        fpsoPortugueseDescription:
          updatedFpso.portugueseTranslation[0].fpsoDescription,
      },
    }).then(() => {
      refetch({
        id: fpsoId || "undefined",
      });
    });
  };

  if (fpsoLoading) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (fpsoError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" color="error">
            {t("errors:fpsoFetchError")}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  // if (error) {
  //   return (
  //     <Grid container>
  //       <Grid item xs={12}>
  //         <Typography variant="body1" color="error">
  //           {t("errors:fpsoUpdateError")}
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  return (
    <>
      {fpsoInfo && fpsoInfo.fpso && fpsoInfo.fpso ? (
        <>
          <Grid container>
            <FpsoBasicDetails
              fpso={fpsoInfo.fpso}
              handleFpsoEdited={handleFpsoEdit}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          {t("errors:fpsoFetchError")}
        </Grid>
      )}
    </>
  );
};
