import { gql } from "@apollo/client";
import { Block } from "Models/Block";
import { BlockWithMarker } from "Models/BlockWithMarker";

export const GET_BLOCKS = gql`
  query getBlocks {
    blocks(order_by: { treeViewOrder: asc }) {
      id
      name
      fpsos {
        id
        name
        fpsoAreas: fpso_areas(order_by: { areaName: asc }) {
          id
          areaName
          currentAuditId
          mainContactEmail
          mainContactName
        }
      }
    }
  }
`;

export interface GetBlocksQueryResult {
  blocks: Block[];
}

export const GET_BLOCKS_WITH_MARKERS = gql`
  query getBlocksWithMarkers {
    blocks {
      id
      name
      mapImageUrl
      mapMarkers {
        blockId
        coordinates
        fpsoId
        name
        markerOffset
      }
    }
  }
`;

export interface GetBlocksWithMarkersQueryResult {
  blocks: BlockWithMarker[];
}

export const GET_BLOCK_WITH_MARKERS_BY_ID = gql`
  query getBlockWithMarkerById($id: uuid!) {
    block: blocks_by_pk(id: $id) {
      id
      name
      mapImageUrl
      mapMarkers {
        blockId
        coordinates
        fpsoId
        name
        markerOffset
      }
    }
  }
`;

export interface GetBlockWithMarkersQueryResult {
  block: BlockWithMarker;
}
